<template>
	 <el-dialog title="二级分类" v-model="secnType" width="50%" center append-to-body @close='closeDialog'>
		<!-- <el-form-item><el-button type="primary" @click="addMaterial()">新增</el-button></el-form-item> -->
		<el-form label-width="100px" class="ruleForm">
			<el-table :data="dataList" border ref="multipleTable">
				<el-table-column prop="id" label="ID" width="100px">
				</el-table-column>
				<el-table-column prop="name" label="二级分类"></el-table-column>
				<el-table-column fixed="right" label="操作" width="300px">
					<template v-slot="scope">
						<el-button icon="el-icon-edit" @click="Edit(scope.row)" type="success" size="small">编辑
						</el-button>
						<el-button icon="el-icon-delete" @click="Deleteinfo(scope.row)" type="danger" size="small">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-size="pagesize" layout="total,sizes,prev,pager,next,jmper"
				:total="totalCount">
			</el-pagination>
		</el-form>
		<div class="clear"></div>	
	</el-dialog>
		<rms_materialSec_edit @closeMaterialSecEdit="closeMaterialSecEdit"  append-to-body="true" v-if="materialSecEditDialogVisible">
		</rms_materialSec_edit>
</template>

<script>
	import rms_materialSec_edit from './rms_materialSec_edit.vue'
	export default {
		created() {
			this.secnType = true;
			this.getSecnType();
			// this.clearinfo();
		},

		data: function() {
			//let self=this;
			return {
				materialSecEditDialogVisible: false,
				fid: this.$parent.id,
				totalCount: 0, //总页数
				pagesize: 10, //每页数量
				currentPage: 1, //初始页
				dataList:[]
			}
		},

		methods: {

			//查询二级分类的访问接口
			getSecnType: function() {
				console.log(1)
				var self = this;
				self.dataList=[];
				var redata = {};
				redata.father_id = self.fid;
				redata.cmd = "searchSecTypes";
				this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas) {
					self.dataList = datas.datas.items;
					self.totalCount = datas.datas.totalCount;
				}, function(data) {
					self.$message(data.reason);
				}, self);
			},
			Edit: function(item) {
				this.name = item.name + "";
				this.id = item.id;
				this.mark = item.mark;
				this.father_id = item.father_id;
				this.mod = 'edit';
				this.materialSecEditDialogVisible = true;
			},
			Deleteinfo: function(item) {
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.deleteSecnType(item.id);
				});
			},
			deleteSecnType: function(id) {
				var self = this;
				var req = {};
				req.id = id;
				req.cmd = "deleteSecTypeById"
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
					self.$message("删除成功");
					// self.closeDialog();
					self.getSecnType();
				}, function(data) {}, self);
			},
			closeMaterialSecEdit: function() {
				this.materialSecEditDialogVisible = false;
				// this.secnType = false;
				// self.getSecnType();
				// this.$emit('closeSecnTypeInfo'); //通知父组件改变。
			},
			closeDialog: function() {
				this.secnType = false;
				this.clearinfo();
				this.$emit('closeSecnTypeInfo'); //通知父组件改变。
			},
			clearinfo: function() {
				this.description = "";
			},

		},


		mounted() {

		},
		components: {
			rms_materialSec_edit
		}
	}
</script>

<style scoped>

</style>
