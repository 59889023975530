<template>
	<el-dialog title="编辑分类" v-model="materialEditDialogVisible" width="30%" center :close-on-click-modal = "false" @close='closeMaterialEditinfo'>
		<el-form label-width="100px" class="ruleForm">	
			<el-form-item label="类别名称" prop="realname" >
			  <el-input v-model="name"></el-input>
			</el-form-item>
			<!-- <el-form-item label="父ID" prop="realname" >
				<el-select v-model="father_id" placeholder="请选择" style="width:435px" clearable>
				  <el-option v-for="data in fids" :key="data.id" :label="data.name" :value="data.id"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="类别编号" prop="number" v-show="showInfo">
			  <el-input v-model="number"></el-input>
			</el-form-item>
			<el-form-item label="价格" prop="price" v-show="showInfo">
			  <el-input v-model="price" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			
			<el-form-item label="规格" prop="specifications" v-show="showInfo">
			  <el-input v-model="specifications"></el-input>
			</el-form-item>
			
			<el-form-item label="单位" prop="unit" v-show="showInfo">
			  <el-input v-model="unit"></el-input>
			</el-form-item>
			
			<el-form-item label="库存数量" prop="quantity" v-show="showInfo">
			  <el-input v-model="quantity" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			
			<el-form-item label="最低预警库存" prop="quantity_lowest" v-show="showInfo">
			  <el-input v-model="quantity_lowest" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			<el-form-item label="备注" prop="realname" >
			  <el-input v-model="mark"></el-input>
			</el-form-item>
		</el-form>
		<div class="ucForm-group-ad">
		  <div class="ucForm-group ucBtn-bg">
		    <button class="ucBtn ucBtn-ok ucBtn-blue" @click="commit()"><i class="iImg"></i>确定</button>
		    <button class="ucBtn ucBtn-refresh ucBtn-gray" @click="closeMaterialEditinfo()"><i
		        class="iImg"></i>返回</button>
		  </div>
		</div>
		<div class="clear"></div>
	</el-dialog>
</template>
<script>
	
	export default {
		name: 'ZhProvider',
		
		data: function() {
			
			return {
				name:this.$parent.name,
				father_id:this.$parent.father_id,
				mark:this.$parent.mark,
				id:this.$parent.id,
				plot_id : this.$parent.article,
				fids:[],
				number:this.$parent.number,
				price:this.$parent.price,
				unit:this.$parent.unit,
				specifications:this.$parent.specifications,
				quantity:this.$parent.quantity,
				total_price:this.$parent.total_price,
				quantity_lowest:this.$parent.quantity_lowest,
				showInfo:this.$parent.father_id > 0 ? true :false
			}
		},
		created() {
			this.materialEditDialogVisible = true;
			this.getfid()
	
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				req.id = this.id
				if(this.name===null||this.name==''){
					self.$message("类型名称不能为空");
					return;
				}else{
					req.name = this.name;
				}
				if(this.father_id===null||this.father_id===''){
					self.$message("父ID不能为空");
					return;
				}else{
					req.father_id = this.father_id;
					
					if(this.number == null || this.number == ''){
						self.$message("编号不能为空");
						return;
					}
					if(this.price == null || this.price == ''){
						self.$message("价格不能为空");
						return;
					}
					
					if(this.quantity == null || this.quantity == ''){
						self.$message("库存数量不能为空");
						return;
					}
					
					req.number = this.number;
					req.price =  parseFloat(this.price);
					req.unit = this.unit;
					req.specifications = this.specifications;
					req.quantity = parseFloat(this.quantity);
					req.total_price = this.quantity * this.price;
					req.quantity_lowest = this.quantity_lowest || null;
					
				}
				req.plot_id = this.plot_id;
				req.mark = this.mark;
				req.cmd = "updateMaterialById"
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message(res.reason);
					if(res.result == "OK"){
						self.closeMaterialEditinfo();
					}
				}, null, self);
				
			},
			getfid:function(){
				var req = {};
				req.cmd = "searchMaterialTypes";
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
				  console.log(res.datas.idList);
				  this.fids.push({id:0,name:"请选择"});
				  for(var i=0;i<res.datas.items.length;i++){
					  this.fids.push(res.datas.items[i])
				  }
				}, null, this);
			},
			closeMaterialEditinfo: function() {
				this.materialEditDialogVisible = false;
				this.clearinfo();
				this.$emit('closeMaterialEdit'); //通知父组件改变。
			},
			clearinfo:function(){
			   this.description = "";
			},
		},
		mounted() {
			// this.$nextTick(function(){
			// 	this.drawcategory('main');
			// })
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
