<template>
	<el-dialog title="编辑二级分类" v-model="materialSecEditDialogVisible" width="30%" center :close-on-click-modal="false"
		@close='closeSecEditinfo'>
		<el-form label-width="100px" class="ruleForm">
			<el-form-item label="类别名称" prop="realname">
				<el-input v-model="name"></el-input>
			</el-form-item>
			<el-form-item label="父ID" prop="realname" >
				<el-select v-model="father_id" placeholder="请选择" style="width:435px" clearable>
				  <el-option v-for="data in fids" :key="data.id" :label="data.name" :value="data.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注" prop="realname">
				<el-input v-model="mark"></el-input>
			</el-form-item>
		</el-form>
		<div class="ucForm-group-ad">
			<div class="ucForm-group ucBtn-bg">
				<button class="ucBtn ucBtn-ok ucBtn-blue" @click="commit()"><i class="iImg"></i>确定</button>
				<button class="ucBtn ucBtn-refresh ucBtn-gray" @click="closeSecEditinfo()"><i
						class="iImg"></i>返回</button>
			</div>
		</div>
		<div class="clear"></div>

	</el-dialog>
</template>
<script>
	export default {

		data: function() {

			return {
				name: this.$parent.name,
				father_id: this.$parent.father_id,
				mark: this.$parent.mark,
				id: this.$parent.id,
				fids:[]
			}
		},
		created() {
			this.materialSecEditDialogVisible = true;
			this.getfid()
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				req.id = this.id
				if (this.name == null || this.name == '') {
					self.$message("类型名称不能为空");
					return;
				} else {
					req.name = this.name;
				}
				if (this.father_id === null || this.father_id === '') {
					req.father_id = 0
				} else {
					req.father_id = this.father_id;
				}
				req.mark = this.mark;
				req.cmd = "updateMaterialById"
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
					self.$message("修改成功");
					self.closeSecEditinfo();
					this.$parent.getSecnType();
				}, null, self);

			},
			getfid:function(){
				var req = {};
				req.cmd = "searchMaterialTypes";
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
				  console.log(res.datas.idList);
				  this.fids.push({id:0,name:"请选择"});
				  for(var i=0;i<res.datas.items.length;i++){
					  this.fids.push(res.datas.items[i])
				  }
				}, null, this);
			},
			closeSecEditinfo: function() {
				this.materialSecEditDialogVisible = false;
				this.clearinfo();
				this.$emit('closeMaterialSecEdit'); //通知父组件改变。
				// this.$parent.getSecnType();
			},
			clearinfo: function() {
				this.description = "";
			},
		},
		mounted() {
			// this.$nextTick(function(){
			// 	this.drawcategory('main');
			// })
		},
		components: {

		}
	}
</script>

<style scoped>

</style>
