<template>
	<el-dialog title="新增分类" v-model="oneTypeInfoDialogVisible" width="30%" center :close-on-click-modal = "false" @close='closeOneinfo'>
		<el-form label-width="100px" class="ruleForm">	
			<el-form-item label="类别名称" prop="realname" >
			  <el-input v-model="name"></el-input>
			</el-form-item>
			<el-form-item label="父ID" prop="realname" >
				<el-select v-model="father_id" placeholder="请选择" style="width:278px" clearable @change="plotIdChange">
				  <el-option v-for="data in fids" :key="data.id" :label="data.name" :value="data.id"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item label="类别编号" prop="number" v-show="showInfo">
			  <el-input v-model="number"></el-input>
			</el-form-item>
			<el-form-item label="价格" prop="price" v-show="showInfo">
			  <el-input v-model="price" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			
			<el-form-item label="规格" prop="specifications" v-show="showInfo">
			  <el-input v-model="specifications"></el-input>
			</el-form-item>
			
			<el-form-item label="单位" prop="unit" v-show="showInfo">
			  <el-input v-model="unit"></el-input>
			</el-form-item>
			
			<el-form-item label="库存数量" prop="quantity" v-show="showInfo">
			  <el-input v-model="quantity" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			
			<el-form-item label="最低预警库存" prop="quantity_lowest" v-show="showInfo">
			  <el-input v-model="quantity_lowest" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
			</el-form-item>
			
			<el-form-item label="备注" prop="realname" >
			  <el-input v-model="mark"></el-input>
			</el-form-item>
		</el-form>
		<div class="ucForm-group-ad">
		  <div class="ucForm-group ucBtn-bg">
		    <button class="ucBtn ucBtn-ok ucBtn-blue" @click="commit()"><i class="iImg"></i>确定</button>
		    <button class="ucBtn ucBtn-refresh ucBtn-gray" @click="closeOneinfo()"><i
		        class="iImg"></i>返回</button>
		  </div>
		</div>
		<div class="clear"></div>
	</el-dialog>
</template>
<script>
	export default {
		data: function() {
			return {
				mod:this.$parent.mod,
				name:'',
				father_id:'',
				mark:'',
				id:this.$parent.father_id,
				fids:[],
				plot_id : this.$parent.article,
				number:'',
				price:'',
				unit:'',
				specifications:'',
				quantity:'',
				total_price:'',
				quantity_lowest:'',
				showInfo:false
			}
		},
		created() {
			this.oneTypeInfoDialogVisible = true;
			this.getfid()
		},
		methods: {
			plotIdChange:function(){
				if(this.father_id > 0){
					this.showInfo = true;
				}else{
					this.showInfo = false;
				}
			},
			commit: function() {
				var self = this;
				var req = {};
				
				req.cmd = "insertMaterial";
				if(this.name==null||this.name==''){
					self.$message("类型名称不能为空");
					return;
				}else{
					req.name = this.name;
				}
				if(this.father_id==null||this.father_id==''){
					req.father_id = 0
				}else {
					req.father_id = this.father_id;
					if(this.number == null || this.number == ''){
						self.$message("编号不能为空");
						return;
					}
					if(this.price == null || this.price == ''){
						self.$message("价格不能为空");
						return;
					}
					
					if(this.quantity == null || this.quantity == ''){
						self.$message("库存数量不能为空");
						return;
					}
					
					req.number = this.number;
					req.price =  parseFloat(this.price);
					req.unit = this.unit;
					req.specifications = this.specifications;
					req.quantity = parseFloat(this.quantity);
					req.total_price = this.quantity * this.price;
					req.quantity_lowest = this.quantity_lowest || null;
				}
				req.plot_id = this.plot_id;
				req.mark = this.mark;
				
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message(res.reason);
					if(res.result == "OK"){
						self.closeOneinfo();
						this.$parent.handleClickQuery()
					}
				}, null, self);
			},
			getfid:function(){
				var req = {};
				req.cmd = "searchMaterialTypes";
				req.plot_id = this.plot_id;
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
				  console.log(res.datas.idList);
				  this.fids.push({id:0,name:"请选择"});
				  for(var i=0;i<res.datas.items.length;i++){
					  this.fids.push(res.datas.items[i])
				  }
				}, null, this);
			},
			closeOneinfo: function() {
				this.oneTypeInfoDialogVisible = false;
				this.clearinfo();
				this.$emit('closeOneTypeInfo'); //通知父组件改变。
			},
			clearinfo:function(){
			   this.description = "";
			},
		},
		mounted() {
			// this.$nextTick(function(){
			// 	this.drawcategory('main');
			// })
		},
		components: {
		}
	}
</script>

<style scoped>

</style>
