	<!--用料管理数据列表-->
<template>
  <div>
  <el-form ref="searchForm"   label-width="240" :inline="true">		
  
  <el-form-item label="选择项目:" prop="name">
  <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
    <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
  </el-select>
  </el-form-item>
  
	<el-form-item label="查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
    <el-form-item><el-button type="primary" @click="handleClickQuery()">搜索</el-button></el-form-item>
	<el-form-item><el-button type="primary" @click="addMaterial()">新增</el-button></el-form-item>
	
	<el-form-item>
	    <el-button type="primary" @click="uploadStockRecord()">导出库存表</el-button>
	</el-form-item>
	
	<el-form-item>
	 <el-upload
	    class="upload-demo"
	    :action="fileUrl"
		:show-file-list="false"
		:before-upload="beforeUpload"
	    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
		:on-success="uploadMaterialType"
	  >
	    <el-button type="primary">导入入库记录表</el-button>
		</el-upload>
	</el-form-item>
	
	<el-form-item>
		<a href="/template.xlsx" id="download" class="ucBtn ucBtn-export ucBtn-green" download="导入模板.xls"><i class="iImg"></i>下载模板</a>
	</el-form-item>
	
  </el-form>
  <el-table :data="dataList" border row-key="id"  :tree-props="{children: 'children', hasChildren: 'hasChildren'}" ref="multipleTable">
    <!-- <el-table-column label="序号"  width="100px">
    	<template v-slot="scope">
    		{{ scope.$index+1 }}
    	</template>
    </el-table-column> -->
    <el-table-column prop="name" label="一级分类" ></el-table-column>
	
	<el-table-column prop="number" label="编号" ></el-table-column>
	<el-table-column prop="price" label="价格" ></el-table-column>
	<el-table-column prop="unit" label="单位" ></el-table-column>
	<el-table-column prop="specifications" label="规格" ></el-table-column>
	<el-table-column prop="quantity" label="库存数量" ></el-table-column>
	<el-table-column prop="total_price" label="总价" ></el-table-column>
	<el-table-column prop="quantity_lowest" label="最低预警库存" ></el-table-column>

	 <el-table-column prop="mark" label="备注" ></el-table-column>
    <el-table-column fixed="right" label="操作" width="300px" >
      <template v-slot="scope">
	    <!-- <el-button  icon="el-icon-edit"    @click="LookUp(scope.row)" type="success" size="small">查看</el-button> -->
       <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">编辑</el-button>
       <el-button  icon="el-icon-delete"   @click="Deleteinfo(scope.row)" type="danger" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
    <el-config-provider :locale="locale">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          layout="total,sizes,prev,pager,next,jmper"
          :total="totalCount">
     </el-pagination>
    </el-config-provider>

    <rms_one_type_info @closeOneTypeInfo="closeOneTypeInfo"  v-if="oneTypeInfoDialogVisible"></rms_one_type_info>
  <rms_secn_type_info @closeSecnTypeInfo="closeSecnTypeInfo" v-if="secnTypeInfoDialogVisiable"></rms_secn_type_info>
  <rms_material_edit @closeMaterialEdit = "closeMaterialEdit" v-if="materialEditDialogVisible"></rms_material_edit>
  </div>
</template>
<script>
import rms_one_type_info from './rms_one_type_info.vue'
import rms_secn_type_info from './rms_secn_type_info.vue'
import rms_material_edit from './rms_material_edit.vue'
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },

	data() {
	  let self=this;
	  return {
		fileUrl:this.$skconf("iot.oss.uurl") + "?bucket=23",
	    dataList:[],  //表格数据集
	    oneTypeInfoDialogVisible: false,
		secnTypeInfoDialogVisiable:false,
		materialEditDialogVisible:false,
	    tableDataName:'',   //综合查询条件参数
	    optionsype:'',     //所属项目选项
	    article:'',       //选择的所属项目
	    totalCount:0,          //总页数
	    pagesize:10,        //每页数量
	    currentPage:1    //初始页
	  }
	},

  /*启动后触发该方法*/
  created() {
    //this.handleClickQuery();
	 this.getplotname();
  },
  methods: {	
	  uploadStockRecord:function(){
		  if(this.article == undefined || this.article == ""){
		  	 this.$message("请选择项目")
		  	 return false;
		  }
		  var self = this;
		  var data = {cmd: "uploadStockRecord"}
		  data.plot_id = this.article;
		  this.$sknet.download(this.$skconf("met.surl"), data);
	  },
	beforeUpload:function(){
		if(this.article == undefined || this.article == ""){
			this.$message("请选择项目")
			return false;
		}
	},
	uploadMaterialType: function(file) {
		console.log(data + ":data");
		var self = this;
		var data = {cmd: "uploadMaterialType"}
		data.plot_id = this.article;
		data.ossFilePath = file.files[0].id;            //excel oss路径
		this.$sknet.postJson(this.$skconf("met.surl"), data, function(res){
		  if(res.result == "OK"){
				this.handleClickQuery();
		  }else{
			  this.$message(res.reason);
		  }
		}, function(data) {
		  self.$message(data.reason);
		}, self);
		
	},
	//查询所有项目的方法
	getplotname:function(){
		var self = this;
		self.options;
		var redata = {};
		redata.cmd = "getPmsName";
		redata.id=this.ids;
		this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
		  this.optionsype=datas.datas.items;
		}, function(data) {
		  self.$message(data.reason);
		}, self);
	},
    handleClickQuery:function(){
      this.dataList = [];
      var req = {};
	  if(this.article == undefined || this.article == ""){
	  	this.$message("请选择项目")
	  	return
	  }
	  req.plot_id = this.article;
      req.cmd = "searchMaterialTypes";
	  req.name=this.tableDataName;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
        this.totalCount=res.datas.totalCount;
      }, null, this);
    },
    Modify: function(item) {
      this.name = item.name+"";
	  this.id = item.id;
	  this.mark = item.mark;
	  this.father_id = item.father_id;
      this.mod = 'edit';
	  
	  this.number = item.number;
	  this.price = item.price;
	  this.unit = item.unit;
	  this.specifications = item.specifications;
	  this.quantity = item.quantity;
	  this.total_price = item.total_price;
	  this.quantity_lowest = item.quantity_lowest;
	  
      this.materialEditDialogVisible = true;
    },
    closeOneTypeInfo:function(){
      this.oneTypeInfoDialogVisible = false;
      this.handleClickQuery();
    },
	closeSecnTypeInfo:function(){
		this.secnTypeInfoDialogVisiable = false;
		this.handleClickQuery();
	},
	closeMaterialEdit:function(){
		this.id = "";
		this.materialEditDialogVisible = false;
		this.handleClickQuery();
	},
	addMaterial:function(){
		
		if(this.article == undefined || this.article == ""){
			this.$message("请选择项目")
			return
		}
		
		this.mod = "add";
		this.oneTypeInfoDialogVisible = true;
		
	},
	Deleteinfo:function(item){
	  this.$confirm('是否确认删除?', '提示', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    type: 'warning'
	  }).then(() => {
	    this.deleteOneType(item.id);
	  });
	},	
	deleteOneType:function(id){
		var self = this;
		var req = {};
		req.id = id;
		req.cmd = "deleteOneType"
		this.$sknet.postJson(this.$skconf("met.surl"), req, function(res){
		  self.$message("删除成功");
		  self.handleClickQuery();
		}, function(data) {
		}, self);
	},
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
  },


  components: {
   rms_one_type_info,
   rms_secn_type_info,
   rms_material_edit,
   [ElConfigProvider.name]: ElConfigProvider,

  },

}


</script>